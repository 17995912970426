<template>
  <div class="section-wrapper">
    <div class="header" v-if="text">
      <p :style="{ backgroundColor: color }">{{ text }}</p>
    </div>
    <template>
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    text: { type: String },
    color: { type: String },
  },
};
</script>

<style scoped>
.section-wrapper {
  padding: 50px 0px;
}
.header {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.header p {
  color: white;
  font-weight: 700;
  font-size: 18px;
  border-radius: 8px;
  padding: 6px 12px;
  text-align: center;
}
</style>