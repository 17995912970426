var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ten-years"
  }, [_c('hero-section'), _c('time-lines'), _c('gallery'), _c('certificates'), _c('partners')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }