<template>
  <section-wrapper
    text="معالم على طريق النجاح"
    color="#B89750"
    class="illustration wow fadeInLeft animated"
    data-wow-duration="1s"
  >
    <div
      class="slider"
      ref="slider"
      @mousedown="handleMouseDown"
      @touchstart="handleTouchStart"
      @mouseleave="handleMouseLeave"
      @mouseup="handleMouseUp"
      @touchend="handleTouchEnd"
      @mousemove="handleMouseMove"
      @touchmove="handleTouchMove"
    >
      <div class="time-lines-wrapper" ref="timeLinesWrapper">
        <div
          class="time-line"
          v-for="(timeline, index) in timelines"
          :key="`timeline-${index}`"
        >
          <div class="line" :style="{ backgroundColor: timeline.color }"></div>
          <div class="content">
            <div class="year">{{ timeline.year }}</div>
            <div class="text">
              <p
                v-for="(timelineText, index) in timeline.text"
                :key="`timeline-text-${index}`"
              >
                {{ timelineText }}
                <span :style="{ backgroundColor: timeline.color }"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section-wrapper>
</template>
  
<script>
import SectionWrapper from "../../website-components/ten-years/SectionWrapper.vue";
export default {
  components: {
    SectionWrapper,
  },
  data() {
    return {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      timelines: [
        {
          color: "#BCC22B",
          year: 2014,
          text: [
            "افتتاح مركز الميدان",
            "دمج التميز في كفالة اليتيم مع جمعية الرعاية الاجتماعية",
          ],
        },
        {
          color: "#8162B9",
          year: 2015,
          text: [
            "افتتاح مركز الرعاية الصحية",
            "افتتاح المركز التعليمي",
            "افتتاح المساحة الصديقة للطفولة",
          ],
        },
        {
          color: "#F16F9B",
          year: 2016,
          text: [
            "افتتاح مركز التميز الطبي",
            "تعميم النظام المعلوماتي الخاص بالمؤسسة على بقية الجمعيات التى تعنى باليتيم",
          ],
        },
        {
          color: "#68A3D5",
          year: 2017,
          text: ["افتتاح مراكز دعم المرأة"],
        },
        {
          color: "#36C3D5",
          year: 2019,
          text: [
            "الحصول على شهادة ISO العالمية الاسترالية",
            "افتتاح مركز الشباب",
          ],
        },
        {
          color: "#E4A344",
          year: 2020,
          text: [
            "الحصول على شهادة ISO  العالمية الألمانية",
            "افتتاح مراكز الرعاية الصحية في ريف دمشق",
          ],
        },
        {
          color: "#BCC22B",
          year: 2022,
          text: [
            "افتتاح بازار تنتنة",
            "إطلاق بوابة التبرعات الالكترونية الخاصة بالمؤسسة (كريم)",
          ],
        },
        {
          color: "#8162B9",
          year: 2023,
          text: ["الحصول على شهادة ISO العالمية البريطانية", "افتتاح مركز حلب"],
        },
        {
          color: "#F16F9B",
          year: 2024,
          text: ["نسطر رحلة نجاح جديدة لهذا العام"],
        },
      ],
    };
  },
  methods: {
    handleMouseDown(e) {
      this.isDown = true;
      this.startX = e.pageX - this.$refs.slider.offsetLeft;
      this.scrollLeft = this.$refs.slider.scrollLeft;
      this.$refs.timeLinesWrapper.style.cursor = "grabbing";
    },
    handleTouchStart(e) {
      const touch = e.touches[0];
      this.isDown = true;
      this.startX = touch.pageX - this.$refs.slider.offsetLeft;
      this.scrollLeft = this.$refs.slider.scrollLeft;
    },
    handleMouseLeave() {
      this.isDown = false;
      this.$refs.timeLinesWrapper.style.cursor = "grab";
    },
    handleMouseUp() {
      this.isDown = false;
      this.$refs.timeLinesWrapper.style.cursor = "grab";
    },
    handleTouchEnd() {
      this.isDown = false;
    },
    handleMouseMove(e) {
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.slider.offsetLeft;
      const walk = (x - this.startX) * 1;
      this.$refs.slider.scrollLeft = this.scrollLeft - walk;
    },
    handleTouchMove(e) {
      if (!this.isDown) return;
      e.preventDefault();
      const touch = e.touches[0];
      const x = touch.pageX - this.$refs.slider.offsetLeft;
      const walk = (x - this.startX) * 1;
      this.$refs.slider.scrollLeft = this.scrollLeft - walk;
    },
  },
};
</script>
  
<style scoped>
.slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: grab;
}
.time-lines-wrapper {
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}
.time-lines-wrapper .time-line {
  width: 210px;
  height: 320px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
}
.time-lines-wrapper .time-line .line {
  width: 100%;
  height: 15px;
}
.time-lines-wrapper .time-line .content {
  width: 100%;
  padding: 5px 12px;
  position: absolute;
  height: 152.5px;
}
.time-lines-wrapper .time-line .content .year {
  font-weight: 800;
  font-size: 30px;
}
.time-lines-wrapper .time-line .content .text {
  font-weight: 500;
  font-size: 16px;
}
.time-lines-wrapper .time-line .content .text p {
  line-height: 21px;
  position: relative;
}
.time-lines-wrapper .time-line .content .text p span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 7px;
  right: -9px;
}
.time-lines-wrapper .time-line:nth-child(odd):after {
  content: "";
  width: 2px;
  height: 180px;
  background: #474a54;
  position: absolute;
  top: 0px;
  left: 0px;
}
.time-lines-wrapper .time-line:first-child:before {
  content: "";
  width: 2px;
  height: 180px;
  background: #474a54;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.time-lines-wrapper .time-line:nth-child(odd) .content {
  top: 0px;
}
.time-lines-wrapper .time-line:nth-child(even):after {
  content: "";
  width: 2px;
  height: 180px;
  background: #474a54;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.time-lines-wrapper .time-line:nth-child(even) .content {
  bottom: 0px;
}
</style>