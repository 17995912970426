var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-wrapper"
  }, [_vm.text ? _c('div', {
    staticClass: "header"
  }, [_c('p', {
    style: {
      backgroundColor: _vm.color
    }
  }, [_vm._v(_vm._s(_vm.text))])]) : _vm._e(), [_vm._t("default")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }