var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "certificates illustration wow fadeInUp animated",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('section-wrapper', {
    attrs: {
      "text": "مشروع محلّي بامتيازات عالمية",
      "color": "#8AB3E9"
    }
  }, [_c('div', {
    staticClass: "row fadeInUp animated"
  }, _vm._l(_vm.certificates, function (certificate, index) {
    return _c('div', {
      key: ("certificate-" + index),
      staticClass: "col-lg-4 col-sm-6 col-12"
    }, [_c('div', {
      staticClass: "certificate"
    }, [_c('div', {
      staticClass: "image"
    }, [_c('img', {
      attrs: {
        "src": certificate.image
      }
    })]), _c('div', {
      staticClass: "text"
    }, [_c('p', [_vm._v(" " + _vm._s(certificate.text) + " ")])])])]);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }