var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gallery illustration wow fadeInUp animated",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('section-wrapper', {
    attrs: {
      "text": "حكاية إحسان ترويها السنين",
      "color": "#BCC22B"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-1.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-6 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-2.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-3.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-4.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-5.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-6.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-8 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-7.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-4 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-8.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-9.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-10.png")
    }
  })])]), _c('div', {
    staticClass: "col-md-6 col-12"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/gallery-11.png")
    }
  })])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }