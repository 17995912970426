var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section-wrapper', {
    staticClass: "illustration wow fadeInLeft animated",
    attrs: {
      "text": "معالم على طريق النجاح",
      "color": "#B89750",
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    ref: "slider",
    staticClass: "slider",
    on: {
      "mousedown": _vm.handleMouseDown,
      "touchstart": _vm.handleTouchStart,
      "mouseleave": _vm.handleMouseLeave,
      "mouseup": _vm.handleMouseUp,
      "touchend": _vm.handleTouchEnd,
      "mousemove": _vm.handleMouseMove,
      "touchmove": _vm.handleTouchMove
    }
  }, [_c('div', {
    ref: "timeLinesWrapper",
    staticClass: "time-lines-wrapper"
  }, _vm._l(_vm.timelines, function (timeline, index) {
    return _c('div', {
      key: ("timeline-" + index),
      staticClass: "time-line"
    }, [_c('div', {
      staticClass: "line",
      style: {
        backgroundColor: timeline.color
      }
    }), _c('div', {
      staticClass: "content"
    }, [_c('div', {
      staticClass: "year"
    }, [_vm._v(_vm._s(timeline.year))]), _c('div', {
      staticClass: "text"
    }, _vm._l(timeline.text, function (timelineText, index) {
      return _c('p', {
        key: ("timeline-text-" + index)
      }, [_vm._v(" " + _vm._s(timelineText) + " "), _c('span', {
        style: {
          backgroundColor: timeline.color
        }
      })]);
    }), 0)])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }