<template>
  <div
    class="certificates illustration wow fadeInUp animated"
    data-wow-duration="1s"
  >
    <section-wrapper text="مشروع محلّي بامتيازات عالمية" color="#8AB3E9">
      <div class="row fadeInUp animated">
        <div
          class="col-lg-4 col-sm-6 col-12"
          v-for="(certificate, index) in certificates"
          :key="`certificate-${index}`"
        >
          <div class="certificate">
            <div class="image">
              <img :src="certificate.image" />
            </div>
            <div class="text">
              <p>
                {{ certificate.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section-wrapper>
  </div>
</template>

<script>
import SectionWrapper from "../../website-components/ten-years/SectionWrapper.vue";
export default {
  components: {
    SectionWrapper,
  },
  data() {
    return {
      certificates: [
        {
          image: require("../../../../../public/assets/images/website/ten-years/certificate-1.png"),
          text: "الحصول على شهادة الآيزو العالمية الاسترالية ISO 9001:2015 عام 2019",
        },
        {
          image: require("../../../../../public/assets/images/website/ten-years/certificate-2.png"),
          text: "الحصول على شهادة الآيزو العالمية الألمانية ISO 9001:2015 عام 2020",
        },
        {
          image: require("../../../../../public/assets/images/website/ten-years/certificate-3.png"),
          text: "الحصول على شهادة الآيزو العالمية البريطانية ISO 9001:2015 عام 2023",
        },
      ],
    };
  },
};
</script>
  
<style scoped>
.row {
  justify-content: center;
}
.col-12 {
  padding: 6px;
  padding-top: 110px;
}
.certificate {
  background: linear-gradient(
    180deg,
    rgba(251, 253, 237, 1) 0%,
    rgba(253, 241, 178, 1) 100%
  );
  border-radius: 18px;
  width: 100%;
  height: 200px;
  position: relative;
}
.certificate .image {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 105px;
  left: 30px;
  width: 130px;
}
.certificate .image img {
  transition: all 0.3s linear;
}
.certificate:hover .image img,
.certificate:hover .image img {
  transform: translateY(-10px) scale(1.08);
}
.certificate .text {
  height: 100%;
  padding: 105px 10px 10px 15px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}
</style>