var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "partners illustration wow fadeInUp animated",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('section-wrapper', {
    attrs: {
      "text": "علاقات وثيقة بنيت مع جهات عالمية",
      "color": "#8162b9"
    }
  }, [_c('div', {
    staticClass: "wrapper"
  }, _vm._l(6, function (n) {
    return _c('img', {
      key: n,
      attrs: {
        "src": require(("../../../../../public/assets/images/website/ten-years/partners-" + n + ".png"))
      }
    });
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }