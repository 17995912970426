<template>
  <div
    class="partners illustration wow fadeInUp animated"
    data-wow-duration="1s"
  >
    <section-wrapper text="علاقات وثيقة بنيت مع جهات عالمية" color="#8162b9">
      <div class="wrapper">
        <img
          v-for="n in 6"
          :key="n"
          :src="
            require(`../../../../../public/assets/images/website/ten-years/partners-${n}.png`)
          "
        />
      </div>
    </section-wrapper>
  </div>
</template>

<script>
import SectionWrapper from "../../website-components/ten-years/SectionWrapper.vue";
export default {
  components: {
    SectionWrapper,
  },
};
</script>
    
<style scoped>
.wrapper {
  display: flex;
  gap: 5px;
  justify-content: center;
  flex-wrap: wrap;
}
.wrapper img {
  width: 160px;
  height: 130px;
  object-fit: contain;
  flex-flow: 1;
  flex-shrink: 0;
}
</style>