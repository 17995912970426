<template>
  <div
    class="gallery illustration wow fadeInUp animated"
    data-wow-duration="1s"
  >
    <section-wrapper text="حكاية إحسان ترويها السنين" color="#BCC22B">
      <div class="row">
        <div class="col-md-6 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-1.png"
            />
          </div>
        </div>
        <div class="col-md-6 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-2.png"
            />
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-3.png"
            />
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-4.png"
            />
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-5.png"
            />
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-6.png"
            />
          </div>
        </div>
        <div class="col-md-8 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-7.png"
            />
          </div>
        </div>
        <div class="col-md-4 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-8.png"
            />
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-9.png"
            />
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-10.png"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="image">
            <img
              src="../../../../../public/assets/images/website/ten-years/gallery-11.png"
            />
          </div>
        </div>
      </div>
    </section-wrapper>
  </div>
</template>
 
<script>
import SectionWrapper from "../../website-components/ten-years/SectionWrapper.vue";
export default {
  components: {
    SectionWrapper,
  },
};
</script>

<style scoped>
.col-6,
.col-12 {
  padding: 6px;
}
.gallery .image {
  overflow: hidden;
  border-radius: 18px;
}
.gallery .image img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  transition: all 0.3s linear;
}
.image:hover img,
.image:hover img {
  transform: scale(1.2);
}
@media screen and (max-width: 800px) {
  .gallery .image img {
    height: 180px;
  }
}
</style>