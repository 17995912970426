var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-section illustration wow fadeInDown animated",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('img', {
    staticClass: "top",
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/hero-8.png")
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/hero-7.png")
    }
  }), _c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/hero-5.png")
    }
  })]), _c('img', {
    staticClass: "bottom",
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/hero-8.png")
    }
  })]);
}]

export { render, staticRenderFns }