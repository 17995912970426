<template>
  <div class="ten-years">
    <hero-section />
    <time-lines />
    <gallery />
    <certificates />
    <partners />
  </div>
</template>

<script>
import HeroSection from "../website-components/ten-years/HeroSection.vue";
import TimeLines from "../website-components/ten-years/TimeLines.vue";
import Gallery from "../website-components/ten-years/Gallery.vue";
import Certificates from "../website-components/ten-years/Certificates.vue";
import Partners from "../website-components/ten-years/Partners.vue";
import JSConfetti from "js-confetti";
export default {
  name: "TenYears",
  components: {
    HeroSection,
    TimeLines,
    Gallery,
    Certificates,
    Partners,
  },
  metaInfo: {
    title: "10 أعوام من التميز",
  },
  methods: {
    delay(delayInms) {
      return new Promise((resolve) => setTimeout(resolve, delayInms));
    },
    async showConfetti() {
      const delay = await this.delay(800);
      const jsConfetti = new JSConfetti().addConfetti({
        confettiRadius: 6,
        confettiNumber: 450,
      });
    },
  },
  mounted() {
    this.showConfetti();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

.ten-years {
  padding: 120px 80px 50px 80px;
  font-family: "Tajawal", sans-serif;
}

@media screen and (max-width: 1000px) {
  .ten-years {
    padding: 120px 20px 50px 20px;
  }
}
</style>